import NameSpace from '../name-space';

const NAME_SPACE = NameSpace.BASKET;

export const getAllGoodsInBasket = (state) => state[NAME_SPACE].goods;
export const getUpdateGoodsInBasket = (state) => state[NAME_SPACE].updateGoods;

export const getCurrentRemoveGoods = (state) =>
  state[NAME_SPACE].currentRemoveGoods;
export const getTicketFormatsInBasket = (state) =>
  state[NAME_SPACE].ticketFormats;
export const getRequiredTicketCountError = (state) =>
  state[NAME_SPACE].requiredTicketCountError;
export const getDelivery = (state) => state[NAME_SPACE].delivery;
