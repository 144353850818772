import { ActionCreator as ValidActCreator } from '../validation/validation';
import { ActionCreator as ProfileActCreator } from '../profile/profile';
import { returnAuthConfig } from 'utils/auth';
import { routes } from 'const/routes';
import { MESSAGES } from 'const/messages';
import { updateLocalStorage } from 'utils/utils';
import { AuthorizationStatus } from 'const/const';
import { backendLogger } from 'utils/logger';
import { ActionCreator as BasketActionCreator } from 'reducer/basket/basket';

const initialState = {
  userObj: {},
  authorizationStatus: AuthorizationStatus.NO_AUTH,
  isSendingForm: false,
  redirectStatus: ``,
  redirectSecureCode: ``,
  initCode: ``,
  resetPopupStatus: false,
  isSendMailPopup: false,
  isVerifyPopup: false,
  isBlockSms: false,
  viewConfirmBtn: false,
  isRecoveryMailPopup: false,
  isRealEmail: true,
  isLoadCheckRealEmail: true,
};

export const ActionType = {
  LOGIN_USER: `LOGIN_USER`,
  SET_AUTH: `SET_AUTH`,
  SET_SENDING: `SET_SENDING`,
  SET_REDIRECT_STATUS: `SET_REDIRECT_STATUS`,
  SET_REDIRECT_CODE: `SET_REDIRECT_CODE`,
  SET_INIT_CODE: `SET_INIT_CODE`,
  SET_RESET_POPUP: `SET_RESET_POPUP`,
  SET_SEND_MAIL_POPUP: `SET_SEND_MAIL_POPUP`,
  SET_VERIFY_POPUP: `SET_VERIFY_POPUP`,
  SET_BLOCK_SENDING_SMS: `SET_BLOCK_SENDING_SMS`,
  VIEW_CONFIRM_BTN: `VIEW_CONFIRM_BTN`,
  SET_RECOVERY_MAIL_POPUP: `SET_RECOVERY_MAIL_POPUP`,
  SET_IS_REAL_EMAIL: 'SET_IS_REAL_EMAIL',
  SET_IS_LOAD_CHECK_REAL_EMAIL: 'SET_IS_LOAD_CHECK_REAL_EMAIL',
};

export const ActionCreator = {
  loginUser: (userObj) => ({
    type: ActionType.LOGIN_USER,
    payload: userObj,
  }),

  setIsRealEmail: (email) => ({
    type: ActionType.SET_IS_REAL_EMAIL,
    payload: email,
  }),
  setIsLoadCheckRealEmail: (isLoad) => ({
    type: ActionType.SET_IS_LOAD_CHECK_REAL_EMAIL,
    payload: isLoad,
  }),
  setAuthorization: (status) => ({
    type: ActionType.SET_AUTH,
    payload: status,
  }),

  setSendingForm: (status) => ({
    type: ActionType.SET_SENDING,
    payload: status,
  }),

  setRedirectStatus: (status) => ({
    type: ActionType.SET_REDIRECT_STATUS,
    payload: status,
  }),

  setRedirectCode: (code) => ({
    type: ActionType.SET_REDIRECT_CODE,
    payload: code,
  }),

  setInitCode: (code) => ({
    type: ActionType.SET_INIT_CODE,
    payload: code,
  }),

  setResetPasswordPopup: (status) => ({
    type: ActionType.SET_RESET_POPUP,
    payload: status,
  }),

  setSendMailPopup: (status) => ({
    type: ActionType.SET_SEND_MAIL_POPUP,
    payload: status,
  }),

  setVerifyPopup: (bool) => ({
    type: ActionType.SET_VERIFY_POPUP,
    payload: bool,
  }),

  setBlockSendingSms: (bool) => ({
    type: ActionType.SET_BLOCK_SENDING_SMS,
    payload: bool,
  }),

  viewConfirmBtn: (bool) => ({
    type: ActionType.VIEW_CONFIRM_BTN,
    payload: bool,
  }),

  setRecoveryMailPopup: (bool) => ({
    type: ActionType.SET_RECOVERY_MAIL_POPUP,
    payload: bool,
  }),
};

// Async functions
export const Operation = {
  registration: (userObj) => async (dispatch, getState, api) => {
    const response = await api.post(`/api/user`, userObj);

    if (response.status === 200) {
      dispatch(ActionCreator.setSendMailPopup(true));
    } else if (response.status >= 400) {
      dispatch(ValidActCreator.setLoginError(`${response.data.message}`));
    }
    dispatch(ActionCreator.setSendingForm(false));
  },

  getRegistrationSmsCode: (telObj) => async (dispatch, getState, api) => {
    const response = await api.post(`/api/user/code`, telObj);
    dispatch(ActionCreator.setBlockSendingSms(false));

    if (response.status === 200) {
      // param for countdown (sms)
      dispatch(ActionCreator.setBlockSendingSms(true));
    } else if (response.status >= 400) {
      backendLogger(response);
      dispatch(ValidActCreator.setTelError(`${response.data.message}`));
    }
    dispatch(ActionCreator.setSendingForm(false));
  },

  registrationSms:
    (userParams, navigation) => async (dispatch, getState, api) => {
      const response = await api.post(`/api/user/phone`, userParams);

      if (response.status === 200) {
        // вынести регистрацию юзера в одну ф-цию/блок
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userObj', JSON.stringify(response.data.user));
        dispatch(ActionCreator.setBlockSendingSms(false)); // off btn send sms
        dispatch(ActionCreator.setAuthorization(AuthorizationStatus.AUTH));
        navigation(routes.main.path);
      } else if (response.status >= 400) {
        dispatch(ValidActCreator.setCodeError(`${response.data.message}`));
      }
      dispatch(ActionCreator.setSendingForm(false));
    },

  verifyEmail: (code, navigate) => async (dispatch, getState, api) => {
    const response = await api.post(`/api/user/verify/email`, code);

    if (response.status === 200) {
      dispatch(ActionCreator.setVerifyPopup(true));
      dispatch(ActionCreator.setRedirectStatus(``));
      navigate(routes.login.path);
    } else if (response.status >= 400) {
      dispatch(ActionCreator.setRedirectStatus(`${response.data.message}`));
    }
  },

  verifyIsRealEmail:
    ({ email, setIsRealE }) =>
    async (dispatch, getState, api) => {
      try {
        const response = await api.post(`/api/user/real/email`, { email });

        if (response.status === 200) {
          dispatch(ActionCreator.setIsRealEmail(response.data.message));
          dispatch(ActionCreator.setIsLoadCheckRealEmail(true));
          if (response.data.message) {
            setIsRealE(true);
          } else {
            setIsRealE(false);
          }
        }
      } catch (e) {
        dispatch(ActionCreator.setIsLoadCheckRealEmail(true));
      }
    },

  verifyNewEmail: (code, navigate) => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.post(`/api/user/verify/new/email`, code, config);

    if (response.status === 200) {
      dispatch(ProfileActCreator.changeEmailStatus(MESSAGES.EMAIL_CONFIRM));
      updateLocalStorage(`userObj`, `update`, { email: response.data.email });
      navigate(routes.personal.path);
    } else if (response.status >= 400) {
      dispatch(ActionCreator.setRedirectStatus(response.data.message));
    }
  },

  // авторизация - авторизация по телефону - объединить и сделать одной ф-цией #REF
  login: (authData) => async (dispatch, getState, api) => {
    try {
      const response = await api.put(`/api/auth`, authData);
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userObj', JSON.stringify(response.data.user));
        dispatch(ActionCreator.setAuthorization(AuthorizationStatus.AUTH));
      } else if (response.status >= 400) {
        dispatch(ValidActCreator.setLoginError(`${response.data.message}`));
        dispatch(ActionCreator.setSendingForm(false));
        if (response.status === 418) {
          dispatch(ActionCreator.viewConfirmBtn(true));
        }
      }
    } catch (e) {
      dispatch(ActionCreator.setSendingForm(false));
    }

    dispatch(ActionCreator.setSendingForm(false)); // off loading form
  },

  // Аутентификация по телефону - при успешном ответе запись Токена Авторизации и объекта user в Localstorage, а также переключения состояния приложения в "Авторизованное"
  phoneAuth: (authData) => async (dispatch, getState, api) => {
    const response = await api.put(`/api/auth/phone`, authData);

    if (response.status === 200) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userObj', JSON.stringify(response.data.user));
      dispatch(ActionCreator.setAuthorization(AuthorizationStatus.AUTH));
    } else if (response.status >= 400) {
      dispatch(ValidActCreator.setTelError(`${response.data.message}`));
    }
    dispatch(ActionCreator.setSendingForm(false));
  },

  // Сброс пароля
  resetPassword: (email) => async (dispatch, getStore, api) => {
    const response = await api.post(`/api/auth/resetpassword`, email);

    if (response.status === 200) {
      dispatch(ActionCreator.setRecoveryMailPopup(true));
    } else if (response.status >= 400) {
      dispatch(ValidActCreator.setRecLoginError(`${response.data.message}`));
    }
  },

  checkResetCode: (data, navigate) => async (dispatch, getStore, api) => {
    const response = await api.put(`/api/auth/resetpassword`, data);

    if (response.status === 200) {
      dispatch(ActionCreator.setInitCode(data.code));
      dispatch(ActionCreator.setRedirectCode(response.data.secure));
      dispatch(ActionCreator.setSendMailPopup(false));
      navigate(routes.newPassword.path);
    } else if (response.status >= 400) {
      // невалидные ссылки или тп - вывод сообщения
      dispatch(ActionCreator.setRedirectStatus(`${response.data.message}`));
    }
  },

  setNewPassword: (data, navigate) => async (dispatch, getStore, api) => {
    const response = await api.post(`/api/auth/resetpassword/update`, data);

    if (response.status === 200) {
      dispatch(ActionCreator.setRedirectStatus(``));
      dispatch(ActionCreator.setResetPasswordPopup(true));
      navigate(routes.login.path);
    } else if (response.status >= 400) {
      backendLogger(response);
      // ЗАДИСПАТЧИТЬ ОШИБКУ И ОТОБРАЗИТЬ ЕЕ В КОМПОНЕНТЕ!
    }
  },

  // перепроверка токена на старте приложения
  checkAuth: () => async (dispatch) => {
    const token = localStorage.token;

    if (token) {
      dispatch(ActionCreator.setAuthorization(AuthorizationStatus.AUTH));
    } else {
      dispatch(ActionCreator.setAuthorization(AuthorizationStatus.NO_AUTH));
      localStorage.removeItem('token');
      localStorage.removeItem('userObj');
    }
  },

  logout: () => async (dispatch) => {
    dispatch(ActionCreator.setAuthorization(AuthorizationStatus.NO_AUTH));
    localStorage.removeItem('token');
    localStorage.removeItem('userObj');
    localStorage.removeItem('NoAuthBasketGoods');
    localStorage.removeItem('basketGoods');
    dispatch(BasketActionCreator.getUserBasketGoods([]));
  },

  confirmEmail: () => async (dispatch, getState, api) => {
    const config = returnAuthConfig();
    const response = await api.put(`/api/user/auth/payment`, {}, config);

    if (response.status === 200) {
      // авторизация пользователя + объект юзера
      localStorage.setItem('userObj', JSON.stringify(response.data.user));
      dispatch(ActionCreator.setAuthorization(AuthorizationStatus.AUTH));
    } else if (response.status >= 400) {
      if (response.status === 403) localStorage.removeItem('token');
    }
  },

  repeatConfirmEmail: (email) => async (dispatch, getState, api) => {
    const response = await api.post(`/api/user/confirm/email`, email);

    if (response.status === 200) {
      // попап о подтверждении
      dispatch(ActionCreator.setSendMailPopup(true));
      dispatch(ActionCreator.viewConfirmBtn(false));
      dispatch(ValidActCreator.setLoginError(``));
    } else if (response.status >= 400) {
      // log errors
      dispatch(ValidActCreator.setLoginError(response.data.message));
    }
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOGIN_USER:
      return { ...state, userObj: action.payload };
    case ActionType.SET_IS_REAL_EMAIL:
      return { ...state, isRealEmail: action.payload };
    case ActionType.SET_IS_LOAD_CHECK_REAL_EMAIL:
      return { ...state, isLoadCheckRealEmail: action.payload };
    case ActionType.SET_AUTH:
      return { ...state, authorizationStatus: action.payload };
    case ActionType.SET_SENDING:
      return { ...state, isSendingForm: action.payload };
    case ActionType.SET_REDIRECT_STATUS:
      return { ...state, redirectStatus: action.payload };
    case ActionType.SET_REDIRECT_CODE:
      return { ...state, redirectSecureCode: action.payload };
    case ActionType.SET_INIT_CODE:
      return { ...state, initCode: action.payload };
    case ActionType.SET_RESET_POPUP:
      return { ...state, resetPopupStatus: action.payload };
    case ActionType.SET_SEND_MAIL_POPUP:
      return { ...state, isSendMailPopup: action.payload };
    case ActionType.SET_VERIFY_POPUP:
      return { ...state, isVerifyPopup: action.payload };
    case ActionType.SET_BLOCK_SENDING_SMS:
      return { ...state, isBlockSms: action.payload };
    case ActionType.VIEW_CONFIRM_BTN:
      return { ...state, viewConfirmBtn: action.payload };
    case ActionType.SET_RECOVERY_MAIL_POPUP:
      return { ...state, isRecoveryMailPopup: action.payload };
    default:
      return state;
  }
};
