import { returnElementById } from './utils';

// Фукнция проверки актуального количества доступных билетов перед оплатой
export const checkActualTicketsCount = (ticketFormats, goods) => {
  const tickets = goods.filter((item) => item.selector === 'ticket');
  if (tickets.length > 0) {
    const result = [];

    tickets.forEach((ticket) => {
      if (ticket.team_all) {
        if (ticket.fixed_count !== undefined) {
          ticket.count =
            ticket.fixed_count === false ? ticket.count : ticket.max_count;
        }
      }
      const requiredTicketCount = ticket.count;
      const currentFormats =
        ticketFormats[ticket.event_public_id + '_' + ticket.address];
      const requiredFormat = currentFormats?.find(
        (format) => ticket.public_id === format.public_id
      );
      if (
        ticket?.kind &&
        requiredTicketCount > requiredFormat?.tickets_left?.[ticket?.kind]
      ) {
        result.push(false);
      } else if (
        !ticket?.kind &&
        requiredTicketCount > requiredFormat?.tickets_left?.athlete
      ) {
        result.push(false);
      }
    });

    return result.length > 0 ? false : true;
  } else {
    return true;
  }
};

// function to get object for payment (tickets, shop, certificates) + ?delivery
export const getPaymentObject = (goods, promocodes, email) => {
  const sendGoods = { email, tickets: [], shop: [], certificates: [] };

  goods.forEach((el) => {
    if (el.space) {
      sendGoods.register = true;
    }

    if (el.selector === `shop`) {
      const codeObj = returnElementById(promocodes, el);

      const promocode = codeObj?.code || codeObj?.promocode;

      const proportion = el?.proportions?.find((p) => p?.name === el?.size);

      let newShop = {
        promocode: promocode || null,
        proportion: { public_id: proportion?.public_id },
        product: { public_id: el.public_id },
        count: el.count,
      };

      if (el?.text) {
        newShop = {
          ...newShop,
          text: el.text,
        };
      }

      if (el?.delivery_public_id) {
        newShop = {
          ...newShop,
          delivery_public_id: el.delivery_public_id,
        };
      }

      sendGoods.shop.push(newShop);
    } else if (el.selector === 'ticket') {
      const codeObj = returnElementById(promocodes, el);

      let idName = `format_public_id`,
        countName = `ticket_count`;

      if (el.isTeam) {
        idName = `team_public_id`;
        if (el.full) countName = `team_count`;
      }

      if (el.team_all) {
        if (el.fixed_count !== undefined) {
          el.count = el.fixed_count === false ? el.count : el.max_count;
        }
      }

      let newTicket = {
        event_public_id: el.event_public_id,
        abid: el.abid,
        [idName]: el.public_id,
        [countName]: el.count,
        insurance: el.insurance,
        promocode: codeObj ? codeObj.code : null,
        city_id: el.city.id,
      };
      if (el?.delivery_public_id) {
        newTicket = {
          ...newTicket,
          delivery_public_id: el.delivery_public_id,
        };
      }
      if (el?.info && el?.cluster) {
        newTicket = {
          ...newTicket,
          info: el.info,
          cluster: el?.cluster,
        };
      }
      if (el?.info && !el?.cluster) {
        newTicket = {
          ...newTicket,
          info: el.info,
        };
      }
      if (el?.select_field) {
        newTicket = {
          ...newTicket,
          select_field: el.select_field,
        };
      }
      sendGoods.tickets.push(newTicket);
    } else if (el.selector === 'certificate') {
      let idName = `format_public_id`,
        countName = `ticket_count`;

      if (el.isTeam) {
        idName = `team_public_id`;
        if (el.full) countName = `team_count`;
      }

      if (el.team_all) {
        if (el.fixed_count !== undefined) {
          el.count = el.fixed_count === false ? el.count : el.max_count;
        }
      }
      let newLicense = {
        event_public_id: el.event_public_id,
        [idName]: el.public_id,
        [countName]: el.count,
        insurance: el.insurance,
        promocode: null,
        city_id: el.city.id,
      };

      if (el?.delivery_public_id) {
        newLicense = {
          ...newLicense,
          delivery_public_id: el.delivery_public_id,
        };
      }

      sendGoods.certificates.push(newLicense);
    }
  });

  return sendGoods;
};

// function to get discount object when certificate or tickets in basket
export const getGiftDiscount = (goods) => {
  /* const discountValues = {
    "5%": 0.05,
    "10%": 0.1,
    "14%": 0.14,
    "15%": 0.15,
    "20%": 0.2,
    "25%": 0.25,
  }; */

  let discountVolume = 0;
  let discountValue = 0;

  if (!goods.length) return 0;
  else {
    /*  const certificateGoods = goods.filter(
      (el) => el.selector === "certificate"
    ); */
    /* const costCertificates = certificateGoods.reduce(
      (acc, el) => acc + el.totalCost,
      0
    );
    const countAmountCertificates = certificateGoods.reduce(
      (acc, el) => acc + el.count,
      0
    ); */

    /*     const ticketGoods = goods.filter((el) => el.selector === "ticket"); */
    /*  const costTickets = ticketGoods.reduce((acc, el) => acc + el.totalCost, 0);
    const countAmountTickets = ticketGoods.reduce(
      (acc, el) => acc + el.count,
      0
    ); */

    // if (countAmountCertificates > 0) {
    //   switch (countAmountCertificates) {
    //     case 1:
    //       discountValue += Math.round(costCertificates * discountValues['5%'])
    //       discountVolume += 5;
    //       break;
    //     case 2:
    //       discountValue += Math.round(costCertificates * discountValues['10%'])
    //       discountVolume += 10;
    //       break;
    //     case 3:
    //       discountValue += Math.round(costCertificates * discountValues['15%'])
    //       discountVolume += 15;
    //       break;
    //     case 4:
    //       discountValue += Math.round(costCertificates * discountValues['20%'])
    //       discountVolume += 20;
    //       break;
    //     default:
    //       discountValue += Math.round(costCertificates * discountValues['25%'])
    //       discountVolume += 25;
    //       break;
    //   }
    // }

    // if (countAmountTickets >= 2) {
    //   discountValue += costTickets * discountValues['14%'];
    //   discountVolume += 14;
    // }

    return { volume: `${discountVolume}%`, value: discountValue };
  }
};
