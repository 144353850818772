import NameSpace from '../name-space';

const NAME_SPACE = NameSpace.PAYMENT;

export const getPromocodes = (state) => state[NAME_SPACE].promocodes;
const getPromocodeErrors = (state) => state[NAME_SPACE].promocodeErrors;

export const getIsErrorPayment = (state) => state[NAME_SPACE].isErrorPayment;
export const getErrorPayment = (state) => state[NAME_SPACE].errorPayment;
export const getIsPayment = (state) => state[NAME_SPACE].isPayment;
export const getRequestPayment = (state) => state[NAME_SPACE].request;

export const getPromocodeSaleByType = (state, item) => {
  if (item.selector === `shop`) {
    return getPromocodes(state).find(el => {
      return (el.public_id === item.public_id && el.size === item.size);
    });
  } else {
    return getPromocodes(state).find(el => el.public_id === item.public_id);
  }
}

export const getPromocodeErrorByType = (state, item) => {
  if (item.selector === `shop`) {
    return getPromocodeErrors(state).find(err => {
      return (err.public_id === item.public_id && err.size === item.size);
    });
  } else return getPromocodeErrors(state).find(err => err.public_id === item.public_id);
};
