import { returnAuthConfig } from 'utils/auth';
import { getPath } from 'utils/getPath';
import { removeItemBasket, returnNewArrayByType } from 'utils/utils';

const initialState = {
  promocodes: [],
  promocodeErrors: [],
  isErrorPayment: false,
  errorPayment: {},
  isPayment: false,
  request: null,
};

export const ActionType = {
  SET_PROMOCODE: `SET_PROMOCODE`,
  REMOVE_PROMOCODE: `REMOVE_PROMOCODE`,
  SET_PROMOCODE_ERROR: `SET_PROMOCODE_ERROR`,
  REMOVE_PROMOCODE_ERROR: `REMOVE_PROMOCODE_ERROR`,
  IS_ERROR_PAYMENT: `IS_ERROR_PAYMENT`,
  SET_ERROR: `SET_ERROR`,
  IS_PAYMENT: `IS_PAYMENT`,
  SET_REQUEST_PAYMENT: 'SET_REQUEST_PAYMENT',
};

export const ActionCreator = {
  setRequestPayment: (request) => ({
    type: ActionType.SET_REQUEST_PAYMENT,
    payload: request,
  }),

  setPromocodes: (obj) => ({
    type: ActionType.SET_PROMOCODE,
    payload: obj,
  }),

  removePromocodes: (item) => ({
    type: ActionType.REMOVE_PROMOCODE,
    payload: item,
  }),

  setPromocodeError: (error) => ({
    type: ActionType.SET_PROMOCODE_ERROR,
    payload: error,
  }),

  removePromocodeError: (item) => ({
    type: ActionType.REMOVE_PROMOCODE_ERROR,
    payload: item,
  }),

  isErrorPayment: (bool) => ({
    type: ActionType.IS_ERROR_PAYMENT,
    payload: bool,
  }),

  isPayment: (bool) => ({
    type: ActionType.IS_PAYMENT,
    payload: bool,
  }),

  setError: (error) => ({
    type: ActionType.SET_ERROR,
    payload: error,
  }),
};

export const Operation = {
  payment: (obj) => async (dispatch, s, api) => {
    let config;
    if (localStorage.userObj) {
      const currentUserObj = JSON.parse(localStorage.userObj);

      // Если userObj временный, то config = undefined
      // Если userObj постоянный, то возвращаем config
      config = currentUserObj.temporary ? undefined : returnAuthConfig();
    }
    // console.log(obj, 'request');
    const response = await api.post(`/api/payment`, obj, config);

    if (response.status === 200) {
      // Если НЕавторизованный пользователь совершает покупку
      if (!config) {
        // Если токен не вернулся, значит user с таким email уже есть
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userObj', JSON.stringify(response.data.user));
        }
        dispatch(ActionCreator.setRequestPayment(response.data));
        // window.location.href = response.data.redirect_url;
      } else {
        // Если пользователь авторизован, просто redirect на оплат
        //  window.location.href = response.data.redirect_url;
        dispatch(ActionCreator.setRequestPayment(response.data));
      }
    } else if (response.status >= 400) {
      dispatch(ActionCreator.isErrorPayment(true));
    }
  },

  promocode: (obj) => async (dispatch, _, api) => {
    const path = getPath(obj);
    const response = await api.get(path);

    if (response.status === 200) {
      dispatch(
        ActionCreator.setPromocodes({
          ...response.data.promocode,
          ...obj,
          code_count: response.data.available_code_count,
        })
      );
      dispatch(ActionCreator.removePromocodeError(obj));
    } else if (response.status >= 400) {
      dispatch(
        ActionCreator.setPromocodeError({
          ...obj,
          message: response.data.message,
        })
      );
    }
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_PROMOCODE:
      return {
        ...state,
        promocodes: returnNewArrayByType(state.promocodes, action.payload),
      };
    case ActionType.SET_PROMOCODE_ERROR:
      return {
        ...state,
        promocodeErrors: returnNewArrayByType(
          state.promocodeErrors,
          action.payload
        ),
      };
    case ActionType.REMOVE_PROMOCODE:
      return {
        ...state,
        promocodes: removeItemBasket(state.promocodes, action.payload),
      };
    case ActionType.REMOVE_PROMOCODE_ERROR:
      return {
        ...state,
        promocodeErrors: removeItemBasket(
          state.promocodeErrors,
          action.payload
        ),
      };
    case ActionType.IS_ERROR_PAYMENT:
      return { ...state, isErrorPayment: action.payload };
    case ActionType.SET_REQUEST_PAYMENT:
      return { ...state, request: action.payload };

    case ActionType.IS_PAYMENT:
      return { ...state, isPayment: action.payload };
    case ActionType.SET_ERROR:
      return { ...state, errorPayment: action.payload };
    default:
      return state;
  }
};
