import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducer/reducer";
import { createAPI } from "../api";
import loggerMiddleware from "./middlewares/logger";

// Объект API - инициализация для добавления в thunk - аргументом
const api = createAPI(() => {});

// инициализация Store - REDUX
export default function configurateStore(preloadedState) {
  const composeEnhancers =
    process.env.NODE_ENV === "development" ? composeWithDevTools : compose;

  const middlewares =
    process.env.NODE_ENV === "development"
      ? [loggerMiddleware, thunk.withExtraArgument(api)]
      : [thunk.withExtraArgument(api)];

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];

  const composedEnhancers = composeEnhancers(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
