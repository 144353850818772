import Login from 'components/Auth/Login/Login';
import Registration from 'components/Auth/Registration/Registration';
import MyEvents from 'components/MyEvents/MyEvents';
import { AuthorizationStatus } from 'const/const';
import MyOrders from 'components/MyOrders/MyOrders';
import { Personal } from 'pages/Personal';
import Settings from 'components/Personal/Settings/Settings';
import Activity from 'components/Personal/Activity/Activity';
import { MyClubs } from 'components/Clubs/MyClubs';

// All routes in app
export const routes = {
  error: {
    path: `/404`,
    name: `Error`,
  },
  notsupport: {
    path: `/notsupport`,
    name: `notsupport`,
  },
  session: {
    path: '/me/session',
    name: 'session',
  },
  exit: {
    path: '/exit',
    name: 'Exit',
  },
  myClubs: {
    path: '/me/club',
    name: 'MyClubs',
  },
  main: {
    path: `/`,
    name: `Главная`,
  },
  clubs: {
    path: `/clubs`,
    name: `Клубы`,
  },
  login: {
    path: `/login`,
    name: `Авторизация`,
  },
  authpage: {
    path: `/auth`,
    name: `Страница авторизации`,
  },
  registration: {
    path: `/registration`,
    name: `Регистрация`,
  },
  regSms: {
    path: `/registration/sms`,
    name: `Регистрация по смс`,
  },
  newPassword: {
    path: `/newpass`,
    name: `Создание нового пароля`,
  },
  resetPassword: {
    path: `/verify/resetpassword/:code`,
    name: `Сброс пароля`,
  },
  verifyEmail: {
    path: `/verify/email/:code`,
    name: `Подтверждение пароля`,
  },
  verifyNewEmail: {
    path: `/verify/new/email/:code`,
    name: `Подтверждение пароля`,
  },
  event: {
    path: `/event`,
    name: `Мероприятие`,
  },
  personal: {
    path: `/me/setting`,
    name: `Личный кабинет`,
  },
  calendar: {
    path: `/calendar`,
    name: `Календарь мероприятий`,
  },
  basket: {
    path: `/basket`,
    name: `Корзина`,
  },
  thanks: {
    path: `/basket/thanks`,
    name: `Спасибо за покупку`,
  },
  virtualEvents: {
    path: `/virtualEvents`,
    name: `Виртуальные события`,
  },
  about: {
    path: `/about`,
    name: `О нас`,
  },
  partners: {
    path: `/partners`,
    name: `Партнеры`,
  },
  corporate: {
    path: `/corp`,
    name: `Корпоративным клиентам`,
  },
  feedback: {
    path: `/feedback`,
    name: `Обратная связь`,
  },
  myEvents: {
    path: `/me`,
    name: `Мои мероприятия`,
    child: {
      name: `Регистрация билета`,
    },
  },
  myOrders: {
    path: `/me/shop`,
    name: `Мои покупки`,
    child: {
      name: `Детали заказа`,
    },
  },
  token: {
    path: `/token`,
    name: `Токен авторизации`,
  },
  news: {
    path: `/news`,
    name: `Новости Лиги Героев`,
  },
  nizhny: {
    path: `/nizhny`,
    name: `Марафон 800 Нижний Новгород`,
  },
  socialAuth: {
    path: `/user/auth`,
    name: `Авторизация через соцсети`,
  },
  socialAuthPersonal: {
    path: `/user/add/auth`,
    name: `Подключение соцсетей в личном кабинете`,
  },
  smi: {
    path: `/smi`,
    name: `Для СМИ`,
  },
  basketFillOutside: {
    path: `/basket-fill-outside`,
    name: `Добавление товара в корзину по ссылке`,
  },
  add: {
    path: `/add`,
    name: `Добавление товара в корзину по ссылке`,
  },
  shop: {
    path: `/shop`,
    name: `Интернет-магазин`,
  },
  product: {
    path: `/shop/product`,
    name: `Страница товара`,
  },
  category: {
    path: `/shop/category`,
    name: `Категория товаров в интернет-магазине`,
  },
  search: {
    path: `/shop/search`,
    name: `Поиск по товарам в интернет-магазине`,
  },
  photo: {
    path: `/photo`,
    name: `Фото`,
  },
  certificateThanks: {
    path: `/basket/certificateThanks`,
    name: `Спасибо за покупку сертификата`,
  },
  results: {
    path: `/results`,
    name: `Результаты`,
  },
  loginLink: {
    path: `/loginlink`,
    name: `Авторизация по ссылке`,
  },
  merch: {
    path: `/merch`,
    name: `Мерч и сувениры`,
  },
  newyear: {
    path: `/newyear_2022`,
    name: `Новый год`,
  },
  logistics: {
    path: `/logistics`,
    name: `Логистика`,
  },
};

// For breadcrumbs - key === path
export const verifyRoutes = [
  {
    id: routes.verifyEmail.name,
    path: routes.verifyEmail.path,
    dispatch: `verifyEmail`,
  },
  {
    id: routes.verifyNewEmail.name,
    path: routes.verifyNewEmail.path,
    dispatch: `verifyNewEmail`,
  },
  {
    id: routes.resetPassword.name,
    path: routes.resetPassword.path,
    dispatch: `checkResetCode`,
  },
];

// Private routes
export const privateRoutes = [
  {
    id: routes.authpage.name,
    path: routes.authpage.path,
    status: AuthorizationStatus.NO_AUTH,
    pathRedirect: routes.myEvents.path,
    element: <Login />,
  },
  {
    id: routes.login.name,
    path: routes.login.path,
    status: AuthorizationStatus.NO_AUTH,
    pathRedirect: routes.myEvents.path,
    element: <Login />,
  },
  {
    id: routes.registration.name,
    path: routes.registration.path,
    status: AuthorizationStatus.NO_AUTH,
    pathRedirect: routes.myEvents.path,
    element: <Registration />,
  },
  {
    id: routes.personal.name,
    path: routes.personal.path,
    status: AuthorizationStatus.AUTH,
    pathRedirect: routes.login.path,
    element: <Personal PersonalItem={<Settings />} />,
  },
  {
    id: routes.myClubs.name,
    path: routes.myClubs.path,
    status: AuthorizationStatus.AUTH,
    pathRedirect: routes.login.path,
    element: <Personal PersonalItem={<MyClubs />} />,
  },
  {
    id: routes.session.name,
    path: routes.session.path,
    status: AuthorizationStatus.AUTH,
    pathRedirect: routes.login.path,
    element: <Personal PersonalItem={<Activity />} />,
  },
  {
    id: routes.myEvents.name,
    path: routes.myEvents.path,
    status: AuthorizationStatus.AUTH,
    pathRedirect: routes.authpage.path,
    element: <Personal PersonalItem={<MyEvents />} />,
  },
  {
    id: routes.myOrders.name,
    path: routes.myOrders.path,
    status: AuthorizationStatus.AUTH,
    pathRedirect: routes.authpage.path,
    element: <Personal PersonalItem={<MyOrders />} />,
  },
];

// Social auth routes and render
export const authRoutes = [
  {
    id: `socialAuth`,
    net: routes.socialAuth,
    dispatchName: `regSocial`,
    renderUrl(url) {
      return `${window.location.origin}/user/auth/${url}`;
    },
  },
  {
    id: `socialAuthPersonal`,
    net: routes.socialAuthPersonal,
    dispatchName: `addAuthSocial`,
    renderUrl(url) {
      return `${window.location.origin}/user/add/auth/${url}`;
    },
  },
];
