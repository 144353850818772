
import { socialNetsReg } from 'mocks/mockArrays';
import { getLSValue } from 'utils/storage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


const Social = ({styles}) => {
  const [socialList] = useState(getLSValue('userObj', 'socials'));
  const { t } = useTranslation();

  // если соц.сеть подключена показываем иконку подключения,
  // если нет - то ссылку и редирект на соцсеть
  return (
    <div className={`${styles.formsWrap}`}>
      <span className={styles.authTitle}>{t('lk.social.title')}</span>
      <ul className={styles.authList}>
        {
          // socialNetsReg - массив с данными для авторизации
          socialNetsReg.map(net => (
            <li key={net.id} className={styles.authItem}>
              {socialList[net.name] ?
                <div>
                  <button type="button" className={styles.authBtn}>
                    <net.logoProfile className={`${styles.authIcon} ${styles.authIconReg}`} />
                    <span>{net.title}</span>&nbsp;<span style={{fontWeight: 700}}>({t('lk.social.conn')})</span>
                  </button>
                  <span className={styles.messageAuth}>{t('lk.social.connectedText')}</span>
                </div>
              :
                <a className={styles.authBtn} href={net.linkPersonal}>
                  <net.logoProfile className={styles.authIcon} />
                  <span>{net.title}</span>
                </a>
              }
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default Social;
