import NameSpace from "../name-space";

const NAME_SPACE = NameSpace.BANNERS;

export const getShopMainBanners = (state) => state[NAME_SPACE].shopMainSlides;
export const getProjectBanners = (state) => state[NAME_SPACE].projectBanners;

export const getMainBanners = (state, type) => {
  return state[NAME_SPACE].mainScreenSlides.filter((el) => el.banner_type === type);
};
export const getPartnersBanners = (state, type) => {
  return state[NAME_SPACE].partnersBanners.filter((el) => el.banner_type === type);
};
export const getShopBanners = (state, type) => {
  return state[NAME_SPACE].shopMainSlides.filter((el) => el.banner_type === type);
};
