export const returnAuthConfig = () => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      // 'Access-Control-Allow-Origin':'CORS',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': token,
    }
  };
}