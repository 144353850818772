const initialState = {
  shopMainSlides: [],
  mainScreenSlides: [],
  partnersBanners: [],
  projectBanners: []
};

export const ActionType = {
  SET_SHOP_MAIN_SLIDES: "SET_SHOP_MAIN_SLIDES",
  SET_MAIN_SCREEN_SLIDES: "SET_MAIN_SCREEN_SLIDES",
  SET_PARTNERS_BANNERS: "SET_PARTNERS_BANNERS",
  SET_PROJECT_BANNERS: "SET_PROJECT_BANNERS"
};

export const ActionCreator = {
  setShopMainSlides: (slides) => ({
    type: ActionType.SET_SHOP_MAIN_SLIDES,
    payload: slides,
  }),
  setMainScreenSlides: (slides) => ({
    type: ActionType.SET_MAIN_SCREEN_SLIDES,
    payload: slides,
  }),
  setPartnersBanners: (banners) => ({
    type: ActionType.SET_PARTNERS_BANNERS,
    payload: banners,
  }),
  setProjectBanners: (banners) => ({
    type: ActionType.SET_PROJECT_BANNERS,
    payload: banners,
  }),
};

export const Operation = {
  getShopBanners: () => async (dispatch, _, api) => {
    const response = await api.get(`/api/page/banner/shop`);

    if (response.status === 200) {
      dispatch(ActionCreator.setShopMainSlides(response.data.values));
    }
  },
  getMainBanners: () => async (dispatch, _, api) => {
    const response = await api.get('/api/page/banner/main');

    if (response.status === 200) {
      dispatch(ActionCreator.setMainScreenSlides(response.data.values));
    }
  },
  getPartnersBanners: () => async (dispatch, _, api) => {
    const response = await api.get('/api/page/banner/partners');

    if (response.status === 200) {
      dispatch(ActionCreator.setPartnersBanners(response.data.values));
    }
  },
  getProjectBanners: () => async (dispatch, _, api) => {
    const response = await api.get('/api/event_type/project');

    if (response.status === 200) {
      dispatch(ActionCreator.setProjectBanners(response.data.values));
    }
  },
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_SHOP_MAIN_SLIDES:
      return {
        ...state,
        shopMainSlides: action.payload,
      };
    case ActionType.SET_MAIN_SCREEN_SLIDES:
      return {
        ...state,
        mainScreenSlides: action.payload,
      };
    case ActionType.SET_PARTNERS_BANNERS:
      return {
        ...state,
        partnersBanners: action.payload,
      };
    case ActionType.SET_PROJECT_BANNERS:
      return {
        ...state,
        projectBanners: action.payload,
      };

    default:
      return state;
  }
};
