import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routes } from "const/routes";
import { Operation, ActionCreator } from "reducer/tickets/tickets";
import {
  getAllTickets,
  getCancelBuyTicket,
  getLoadTicketsState,
  getPastTicket,
  getTransferTicket,
} from "reducer/tickets/selectors";
import { REG_STATUS } from "const/const";
import { useShallowEqualSelector } from "customHooks/useShallowEqualSelector";
import { useTranslation } from "react-i18next";
import { checkRegDate } from "utils/date";
import styles from "./MyEvents.module.scss";
import Container from "../App/Container/Container";
import TicketEventsCard from "./TicketEventsCard/TicketEventsCard";
import UniversalPopup from "components/Popups/UniversalPopup/UniversalPopup";
import useWindowDimensions from "customHooks/useWindowDimensions";

const getTickets = (tickets, status) => {
  const userObj = JSON.parse(localStorage.userObj);
  return tickets ? tickets?.filter(
    (ticket) => {
      if (ticket.info.registered === status &&
        !checkRegDate(ticket?.event_format?.start_time) &&
        ticket.original_user_public_id !== userObj.public_id) {
        return true;
      }
    }

  ) : [];
};

const getPastTickets = (tickets) => {
  return tickets ? tickets?.filter((el) => checkRegDate(el?.event_format?.start_time)) : [];
};

const MyEvents = (props) => {
  const dispatch = useDispatch();

  /* const events = useSelector((state) => getEvents(state));
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);

  useEffect(() => {
    if (isOnScreen && events.length === 1) {
      dispatch(DataOperation.loadEvents());
    }
  }, [isOnScreen]); */

  const allTickets = useSelector((state) => getAllTickets(state));
  const transferTicket = useSelector((state) => getTransferTicket(state));
  const pastTicket = useSelector((state) => getPastTicket(state));
  const isLoadTickets = useShallowEqualSelector((state) =>
    getLoadTicketsState(state)
  );
  const isCancelBuyTicket = useSelector((state) => getCancelBuyTicket(state));
  const firstLoad = useRef(false);
  const { t } = useTranslation();

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (!firstLoad.current) {
      dispatch(Operation.loadTickets());
      firstLoad.current = true;
    }
    return () => dispatch(ActionCreator.cancelBuyTicket(false));
  }, [dispatch, allTickets, isLoadTickets]);

  const handleClosePopup = () => dispatch(ActionCreator.cancelBuyTicket(false));

  const [isPastTicketTitleClick, setIsPastTicketTitleClick] = useState(false);
  const [isTransferTitleClick, setIsTransferTitleClick] = useState(false);

  const handleTransferClick = () => {
    setIsTransferTitleClick(!isTransferTitleClick);
    dispatch(Operation.getTransferTicket());
  };
  const handlePastClick = () => {
    setIsPastTicketTitleClick(!isPastTicketTitleClick);
    dispatch(Operation.getPastTicket());
  };

  return (
    <>
      {/*  <Header /> */}

      <Container>
        {/*    <Breadcrumbs props={props} /> */}

        {/*  <div className={styles.titleWrap}>
          <div className={styles.buttons}>
            <Link
              className={`${styles.link} ${styles.linkActive}`}
              to={routes.myEvents.path}
            >
              <span>{t("lk.title")}</span>
            </Link>
            <Link className={styles.link} to={routes.myOrders.path}>
              <span>{t("lk.goods")}</span>
            </Link>
            <Link
              className={`${styles.link} ${styles.settingsLink}`}
              to={routes.personal.path}
            >
              <img className={styles.icon} src={GearIcon} alt="Шестеренка" />
              <span>{width > "768" ? t("lk.settings") : ""}</span>
            </Link>
          </div>
        </div> */}
        <h2 className={styles.title}>Билеты</h2>

        {getTickets(allTickets, REG_STATUS.FALSE).length === 0 &&
          getTickets(allTickets, REG_STATUS.TRUE).length === 0 &&
          getPastTickets(allTickets).length === 0 ? (
          <div className={styles.notExistBlock}>
            <h3 className={styles.notExistTitle}>{t("lk.nothingEvents")}</h3>
            <Link to={routes.calendar.path} className={styles.notExistLink}>
              {t("lk.calendarBtn")}
            </Link>
          </div>
        ) : (
          <>
            {getTickets(allTickets, REG_STATUS.TRUE).length ? (
              <section className={styles.regEvents}>
                <h3 className={styles.label}>{t("lk.regTitle")}</h3>
                <ul className={styles.list}>
                  {getTickets(allTickets, REG_STATUS.TRUE).map((item) => (
                    <TicketEventsCard
                      item={item}
                      key={item.public_id}
                      styles={styles}
                      status={REG_STATUS.TRUE}
                    />
                  ))}
                </ul>
              </section>
            ) : null}

            {getTickets(allTickets, REG_STATUS.FALSE).length ? (
              <section className={styles.unregEvents}>
                <h3 className={styles.label}>{t("lk.unregTitle")}</h3>
                <ul className={styles.list}>
                  {getTickets(allTickets, REG_STATUS.FALSE).map((item) => (
                    <TicketEventsCard
                      item={item}
                      key={item.public_id}
                      styles={styles}
                      status={REG_STATUS.FALSE}
                    />
                  ))}
                </ul>
              </section>
            ) : null}

            {/* {getPastTickets(allTickets).length ? (
              <section className={styles.unregEvents}>
                <h3
                  className={`${styles.label} ${!isPastTicketTitleClick && styles.underlineTitle
                    }`}
                  onClick={() =>
                    setIsPastTicketTitleClick(!isPastTicketTitleClick)
                  }
                >
                  {t("lk.pastTickets")}
                </h3>
                <ul className={styles.list}>
                  {isPastTicketTitleClick &&
                    getPastTickets(allTickets).map((item) => (
                      <TicketEventsCard
                        item={item}
                        key={item.public_id}
                        styles={styles}
                        past={true}
                      />
                    ))}
                </ul>
              </section>
            ) : null} */}
          </>
        )}

        <section className={styles.unregEvents}>
          <h3
            className={`${styles.label} ${!isPastTicketTitleClick && styles.underlineTitle
              }`}
            onClick={handlePastClick}
          >
            {"Прошедшие мероприятия"}
          </h3>
          {isPastTicketTitleClick && (
            <ul className={styles.list}>
              {pastTicket?.length ? (
                pastTicket?.map((item) => (
                  <TicketEventsCard
                    item={item}
                    key={item.public_id}
                    styles={styles}
                    past={true}
                  />
                ))
              ) : (
                <>
                  {!pastTicket ? null : <h3>Прошедшие мероприятия не найдены!</h3>}
                </>
              )}
            </ul>
          )}
        </section>


        <section className={styles.transferEvents}>
          <h3
            className={`${styles.label} ${!isTransferTitleClick && styles.underlineTitle
              }`}
            onClick={handleTransferClick}
          >
            {"Переданные билеты"}
          </h3>
          {isTransferTitleClick && (
            <ul className={styles.list}>
              {transferTicket.length ? (
                transferTicket.map((item) => (
                  <TicketEventsCard
                    item={item}
                    key={item.public_id}
                    styles={styles}
                    transfered
                  />
                ))
              ) : (
                <h3>Переданные билеты не найдены!</h3>
              )}
            </ul>
          )}
        </section>

        {/*  <div ref={elementRef}>
          <Similar style={styles.similarWrap} />
        </div> */}
      </Container>

      {/*     <Footer /> */}

      {isCancelBuyTicket && (
        <UniversalPopup>
          <div className={styles.wrapper}>
            <span className={styles.popupText}>Возврат выполнен успешно!</span>
            <button
              className={styles.closePopupBtn}
              type="button"
              onClick={handleClosePopup}
              aria-label="Закрыть"
            />
            <Link to={routes.main.path} className={styles.btnMain}>
              На главную
            </Link>
          </div>
        </UniversalPopup>
      )}
    </>
  );
};

export default MyEvents;
