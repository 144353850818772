import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
// import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools
import i18next from 'i18next';
import './css/index.scss';
import { Operation as UserOperation } from './reducer/user/user';
import { Operation as ShopOperation } from 'reducer/shop/shop';
// import reducer from './reducer/reducer';
import App from 'components/App/App';
// import { createAPI } from './api';
import './i18n';
import configurateStore from 'store/store';
import { initYupMessages } from 'utils/initYupMessages';
import { YMInitializer } from 'react-yandex-metrika';
import Loader from 'components/Auth/Loader/Loader';

// Объект API - инициализация для добавления в thunk - аргументом
// const api = createAPI(() => {});

// // инициализация Store - REDUX
// const composedEnhancers = (process.env.NODE_ENV === 'development'
//   ? composeWithDevTools
//   : compose);

// const enhancer = composedEnhancers(applyMiddleware(thunk.withExtraArgument(api)));

// const store = createStore(
//   reducer,
//   undefined,
//   enhancer
// );

export const store = configurateStore();

store.dispatch(UserOperation.checkAuth());
store.dispatch(ShopOperation.loadItems(`basket`));

initYupMessages();
// set default lg from LS
if (localStorage.lang) i18next.changeLanguage(localStorage.lang);

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <YMInitializer
          accounts={[90909954]}
          options={{ webvisor: false }}
        />
        <App />
      </Provider>
    </Suspense>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root')
);
