import { routes } from "const/routes";
import { Link } from "react-router-dom";
import { useShallowEqualSelector } from "customHooks/useShallowEqualSelector";
import { getEvents } from "reducer/data/selectors";
import { useTranslation } from "react-i18next";
import styles from "./Similar.module.scss";

const Similar = ({ style, isVisible = true }) => {
  const { t } = useTranslation();
  const events = useShallowEqualSelector((state) => getEvents(state));

  if (events.length === 0) return null;
  return (
    <>{isVisible && <div className={style}>
      <div className={styles.titleWrap}>
        <h2 className={styles.title}>{t("basket.similar.title")}</h2>
        <Link className={styles.shopLink} to={routes.calendar.path}>
          {t("basket.similar.btn")}
        </Link>
      </div>
      <ul className={styles.list}>
        {events.slice(0, 4).map((el) => (
          <li
            key={el.title}
            className={styles.item}
            style={{
              backgroundImage: `url(${el?.event_type?.project?.picture_main})`,
              color: el.banners.home_page.text_color,
            }}
          >
            <img
              className={styles.logo}
              width="99"
              height="49"
              src={
                el.banners.home_page.slider_logo !== undefined
                  ? el.banners.home_page.slider_logo
                  : el.event_type.logo
              }
              alt="Лого"
            />
            <h3 className={styles.subtitle}>{el.title}</h3>
            <p className={styles.desc}>{el.event_type.description}</p>
            <b className={styles.city}>
              {el.event_city.length === 1
                ? el.event_city[0].address
                : `В городах России`}
            </b>
            {/*   {el.external_url ? ( */}
            {el?.event_type?.project && el.event_type.project.link.length > 0 && <a
              style={{
                backgroundColor: `${el.banners.home_page.button_color}`,
              }}
              href={el?.event_type?.project?.link}
              className={styles.link}
              target="_blank"
              rel="noreferrer"
            >
              Принять участие
            </a>}
            {/*  ) : (
         <Link
           style={{
             backgroundColor: `${el.banners.home_page.button_color}`,
           }}
           className={styles.link}
           to={el.event_type.project.link}
         >
           Принять участие
         </Link>
         )}  */}
          </li>
        ))}
      </ul>
    </div>}
    </>

  );
};

export default Similar;
