import { useEffect, useCallback, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routes } from "const/routes";
import { useTranslation } from "react-i18next";

import { Operation, ActionCreator as PaymentAC } from "reducer/payment/payment";
import { Operation as DataOperation } from "../../reducer/data/data";
import { Operation as ShopOperation } from "../../reducer/shop/shop";
import { Operation as LocOperation } from "reducer/location/location";
import { Operation as TicketOperation } from "reducer/tickets/tickets";
import { Operation as BasketOperation } from "reducer/basket/basket";
import { ActionCreator } from "reducer/basket/basket";

import {
  getErrorPayment,
  getIsErrorPayment,
  getIsPayment,
  getPromocodes,
  getRequestPayment,
} from "reducer/payment/selectors";
import {
  getDeliveryCities,
  getDeliveryPrice,
  getDeliveryRegions,
} from "reducer/data/selectors";
import {
  getAllGoodsInBasket,
  getRequiredTicketCountError,
  getTicketFormatsInBasket,
} from "reducer/basket/selectors";
import { getPreviewShopItem } from "reducer/shop/selectors";
import { getAllTickets } from "reducer/tickets/selectors";
import { getLocation } from "reducer/location/selectors";

import { REGEX_MAIL } from "const/const";
import { searchDeliveryRegion } from "utils/data";
import { getLSValue } from "utils/storage";
import { setDeliveryOptions } from "mocks/calendarOptions";
import { saleCounter } from "utils/goods";
import {
  checkActualTicketsCount,
  getGiftDiscount,
  getPaymentObject,
} from "utils/basket";

import styles from "./Basket.module.scss";
import "./Basket.scss";

import Similar from "./Similar/Similar";
import Goods from "./Goods/Goods";
import Wrapper from "components/App/Wrapper/Wrapper";
import Header from "components/Header/Header";
import Container from "components/App/Container/Container";
import Breadcrumbs from "components/Calendar/Breadcrumbs/Breadcrumbs";
import Footer from "components/Footer/Footer";
import UniversalPopup from "components/Popups/UniversalPopup/UniversalPopup";
import CustomSelect from "components/Calendar/Select/Select";
import InputPhone from "components/Forms/Input/InputPhone";

import { useValidationMail } from "customHooks/useValidationMail";
import { useValidationPhone } from "customHooks/useValidationPhone";

import mir from "images/svg/Mir__color.svg";
import mc from "images/svg/MC__color.svg";
import visa from "images/svg/Visa__color.svg";
import uniteller from "images/svg/Uniteller____white.svg";
import { getShopBanners } from "reducer/banners/selectors";
import { Operation as BannersOperation } from '../../reducer/banners/banners';
import ExternalCollection from "components/Shop/ExternalCollection";


export const getActualGoodsUpdateData = (currentGoods) => {
  /*   let newGoods = []; */
  /*  if (currentGoods.length) {
     newGoods = currentGoods?.map((item) => ({
       age_min: item?.age_min,
       count: item?.count,
       description: item?.description,
       enabled: item?.enabled,
       event: item?.event,
       event_city: item?.event_city,
       event_public_id: item?.event_public_id,
       format_public_id: item?.format_public_id,
       hidden: item?.hidden,
       info: item?.info,
       select: item?.select,
       insurance: item?.insurance,
       insurance_available: item?.insurance_available,
       insurance_cost: item?.insurance_cost,
       logo: item?.logo,
       notes: item?.notes,
       preregistration: item?.preregistration,
       price: item?.price,
       promocode: item?.promocode,
       public_id: item?.public_id,
       selector: item?.selector,
       start_time: item?.start_time,
       tickets_left: item?.tickets_left,
       title: item?.title,
       totalCost: item?.totalCost,
       clusters: item?.clusters,
       shirt_unavailable: item?.shirt_unavailable,
       age_max: item?.age_max,
       buy_limit: item?.buy_limit,
       team_all: item?.team_all,
       max_count: item?.max_count,
       size: item?.size,
       kind: item?.kind,
       delivery: item?.delivery,
       cluster: item?.cluster,
       city: item?.city,
       address: item?.address,
       delivery: item?.delivery,
       type: item?.type
     }));
   } */
  return currentGoods;
};

const Basket = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const slides = useSelector((state) => getShopBanners(state, "external_shop"));

  const [email, isValidMail, handleInputChange, , setValidMail] =
    useValidationMail(getLSValue(`userObj`, `email`));
  const [phone, isValidPhone, handlePhoneChange, setPhone, setValidPhone] =
    useValidationPhone("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [address, setAddress] = useState("");
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDeliveryDisabled, setIsDeliveryDisabled] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isFindLocation, setIsFindLocation] = useState(false);
  const [isErrors, setErrors] = useState({
    phone: ``,
    firstName: ``,
    secondName: ``,
    address: ``,
  });
  const [deliveryCity, setDeliveryCity] = useState(null);
  const [deliveryReg, setDeliveryReg] = useState(null);
  const [errorDelivery, setErrorDelivery] = useState("");
  const [securityPaymentPopup, setSecurityPaymentPopup] = useState(false);
  const [timePaymentPopupError, setTimePaymentPopupError] = useState(null);
  const [isHiddenDeliveryAddres, setIsHiddenDeliveryAddres] = useState(true);
  const formButton = useRef();
  const location = useSelector((state) => getLocation(state));
  const goods = useSelector((state) => getAllGoodsInBasket(state));

  const promocodes = useSelector((state) => getPromocodes(state));
  const isErrorPayment = useSelector((state) => getIsErrorPayment(state));
  const isPayment = useSelector((state) => getIsPayment(state));
  const requestPayment = useSelector((state) => getRequestPayment(state));
  const errorPayment = useSelector((state) => getErrorPayment(state));
  const deliveryRegions = useSelector((state) => getDeliveryRegions(state));
  const deliveryCities = useSelector((state) => getDeliveryCities(state));
  const deliveryPrice = useSelector((state) => getDeliveryPrice(state));
  const shopGoods = useSelector((state) => getPreviewShopItem(state, "new-shop"));
  const allTickets = useSelector((state) => getAllTickets(state));
  const ticketFormatsInBasket = useSelector((state) =>
    getTicketFormatsInBasket(state)
  );
  const requiredTicketCountError = useSelector((state) =>
    getRequiredTicketCountError(state)
  );

  const paymentFormKeys = requestPayment?.form && Object.keys(requestPayment.form);
  useEffect(() => {
    if (formButton && requestPayment) {
      formButton.current.click();
    }
    return () => dispatch(PaymentAC.setRequestPayment(null));
  }, [formButton, requestPayment]);

  useEffect(() => {
    const localstorageGoods = localStorage.getItem("basketGoods") && JSON.parse(localStorage.getItem("basketGoods"));

    if (!goods?.length && localstorageGoods?.length) {
      dispatch(BasketOperation.getUserBasketGoods());
    }
  }, []);

  useEffect(() => {
    const filteredPreRegGoods = goods?.filter((el) => el.preregistration);
    const preReg = filteredPreRegGoods.filter((el) => el.preReg === "ok" || el.preReg === "later");

    if (preReg.length === filteredPreRegGoods.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [goods, isUpdate]);

  useEffect(() => {
    const filteredGoods = goods?.filter((el) => el?.isHiddenDeliveryAddres);
    const shopGoods = goods?.filter((el) => el.selector === "shop");

    if (filteredGoods.length === shopGoods?.length) {
      setIsHiddenDeliveryAddres(false);
    } else {
      setIsHiddenDeliveryAddres(true);
    }

    const currentPickupTrue = goods?.filter((el) => el?.currentPickupSelect);

    if (currentPickupTrue?.length === shopGoods?.length) {
      setIsHiddenDeliveryAddres(false);
    } else {
      setIsHiddenDeliveryAddres(true);
    }

  }, [goods]);

  useEffect(() => {
    const deliveryGoods = goods?.filter((el) => el?.delivery_method);
    const filteredGoods = goods?.filter((el) => el?.delivery_public_id);

    if (deliveryGoods.length === filteredGoods.length) {
      setIsDeliveryDisabled(false);
    } else {
      setIsDeliveryDisabled(true);
    }

  }, [goods, isUpdate]);

  useEffect(() => {
    slides?.length === 0 && dispatch(BannersOperation.getShopBanners());
  }, []);
  const isVipUser = getLSValue("userObj", "role") && getLSValue("userObj", "role").find((el) => el === "vip"); // Проверка относится ли user к категории vip

  useEffect(() => {
    if (isVipUser && !allTickets.length) {
      dispatch(TicketOperation.loadTickets());
    }
  }, [isVipUser, allTickets, dispatch]);

  useEffect(() => {
    dispatch(DataOperation.loadEvents());
    dispatch(LocOperation.getLocation());
  }, []);

  // Загрузить товары для магазина (свайпер)
  useEffect(() => {
    if (!shopGoods.length) dispatch(ShopOperation.loadPreviewShopItems());
  }, [dispatch, shopGoods]);

  // Загрузить регионы доставки
  useEffect(() => {
    if (isDelivery) {
      dispatch(DataOperation.loadDeliveryRegions());
    }
  }, [dispatch, isDelivery]);

  // Загрузить города доставки, выбранного региона
  useEffect(() => {
    if (isDelivery) {
      deliveryReg?.id &&
        dispatch(DataOperation.loadDeliveryCities(deliveryReg.id));
      setDeliveryCity(null);
    }
  }, [dispatch, deliveryReg]);

  useEffect(() => {
    if (goods.some((el) => el.delivery)) {
      setIsDelivery(true);
    }
  }, [goods]);

  // Если в корзине есть товар с опцией доставки,
  // то через location ищем регион пользователя
  // по умолчанию устанавливается Москва - регион доставки
  useEffect(() => {
    if (isDelivery && deliveryRegions.length && location.region.id && !isFindLocation) {
      setIsFindLocation(true);

      if (
        location &&
        location.region &&
        searchDeliveryRegion(deliveryRegions, location.region.id, "region")
      ) {
        setDeliveryReg(
          searchDeliveryRegion(deliveryRegions, location.region.id, "region")
        );
      } else {
        setDeliveryReg(searchDeliveryRegion(deliveryRegions, 524894, "region"));
      }
    }
  }, [isDelivery, location, deliveryRegions, isFindLocation]);

  useEffect(() => {
    const findCity = searchDeliveryRegion(
      deliveryCities,
      location?.city?.id,
      "city"
    );
    if (deliveryReg?.id && location && findCity?.id) {
      setDeliveryCity(
        searchDeliveryRegion(deliveryCities, location.city.id, "city")
      );
    } else {
      setDeliveryCity(null);
    }
  }, [location, deliveryCities, deliveryReg]);

  // Получить стоимость доставки
  useEffect(() => {
    if (isDelivery && deliveryCity?.id) {
      const deliveryPriceObject = { city: { id: deliveryCity?.id }, goods: [] };

      goods.forEach((el) => {
        if (el.delivery) {
          deliveryPriceObject.goods.push({
            public_id: el.public_id,
            count: el.count,
          });
        }
      });
      dispatch(DataOperation.setDeliveryPrice(deliveryPriceObject));
    }
  }, [dispatch, isDelivery, deliveryCity]);

  useEffect(() => {
    let discount = 0;
    let discountPromo = [];

    const isZabegOnline = goods?.filter((el) => el?.format_public_id === "zabeg2024_online_999999_1" ||
      el?.format_public_id === "zabeg2024_online_999999_5" ||
      el?.format_public_id === "zabeg2024_online_999999_10" ||
      el?.format_public_id === "zabeg2024_online_999999_21");

    let isZabegOnlineCount = 0;
    isZabegOnline?.forEach((el) => {
      isZabegOnlineCount = isZabegOnlineCount + el?.count;
    });

    discountPromo = promocodes
      .map((sale) => {
        if (sale.discount_form === `percent`) {
          const oneGoods = goods.find((g) => g.public_id === sale.public_id);
          return saleCounter(oneGoods, sale);
        } else {
          const currentGoods = goods.find((g) => g.public_id === sale.public_id);
          if (sale?.available_count >= currentGoods?.count) {
            return sale.discount && sale.discount > 0 ? sale.discount * currentGoods.count : 0;
          } else {
            return sale.discount && sale.discount > 0 ? sale.discount * sale?.available_count : 0;
          }
        }
      })
      .reduce((a, b) => a + b, 0)
      .toFixed(0);

    if (isZabegOnlineCount >= 3) {
      const isDiscountZabeg = isZabegOnline?.filter((el) => promocodes?.some((item) => el?.format_public_id === item?.public_id));
      const currentDiscount = isZabegOnline?.filter((el) => isDiscountZabeg?.every((item) => el?.format_public_id !== item?.format_public_id));

      if (currentDiscount?.length && isZabegOnline?.length && (isZabegOnline?.length !== currentDiscount?.length)) {
        discount = currentDiscount.map((el) => {
          return el.price * el.count * (15 / 100);
        }).reduce((a, b) => a + b, 0)
          .toFixed(0);
      } else if (!currentDiscount?.length && promocodes.length && (isDiscountZabeg?.length === promocodes?.length || promocodes?.length === goods?.length)) {
        discount = 0;
      }
      else {
        discount = isZabegOnline?.map((el) => {
          return el.price * el.count * (15 / 100);
        }).reduce((a, b) => a + b, 0)
          .toFixed(0);
      }
    }
    // счетчик скидки
    setValidMail(REGEX_MAIL.test(email)); // валидация мейла при автозаполнии
    // setValidPhone(REGEX_PHONE.test(phone))

    setTotalDiscount(+discountPromo + +discount);

  }, [promocodes, goods, email, setValidMail, phone, setValidPhone]);

  // Certificate discount counter
  const giftDiscount = useMemo(() => getGiftDiscount(goods), [goods]);

  const handleInputs = (evt) => {
    evt.preventDefault();
    const { target } = evt;

    if (target.name === "name") setFirstName(target.value);
    else if (target.name === "surname") setSecondName(target.value);
    else if (target.name === "address") setAddress(target.value);
  };

  // Close Popup payment error
  const handlePaymentError = () => {
    dispatch(PaymentAC.isErrorPayment(false));
    dispatch(PaymentAC.isPayment(false));
  };

  const handleDeliveryResetError = () => setErrorDelivery("");

  const handlePhoneFocus = (evt) => {
    const { name } = evt.target;
    setErrors((prev) => ({ ...prev, [name]: `` }));
  };

  // #TODO Сделать выбор региона и города через функцию,а не через UseEffects
  // const handleDeliveryFilter = (evt, filter) => {};

  // ф-ция для оплаты - работает при валидной email, если в корзине только билеты
  // получает товары из стейта - и создает новый объект для отправки на сервер
  // объект вида sendGoods = { email, tickets: [], shop: [], certificates: [] };
  const handlePayment = useCallback(
    (evt) => {
      evt.preventDefault();

      const findSpaceToTickets = goods.filter((el) => el.space);
      const correctPaymentTime = 600000;
      // 600 000 - 10 minute
      let isCorrectTime = true;

      if (findSpaceToTickets?.length) {
        findSpaceToTickets.forEach((el) => {
          if ((Date.now() - el.addTicketToBasketTime) > correctPaymentTime) {
            setTimePaymentPopupError(el);
            dispatch(BasketOperation.removeShop(el));
            isCorrectTime = false;
            return;
          }
        });
      }
      if (!isCorrectTime) {
        return;
      }
      if (isHiddenDeliveryAddres && isDelivery) {

        if (!firstName && deliveryPrice)
          setErrors((prev) => ({ ...prev, firstName: `Заполните поле` }));
        if (!secondName && deliveryPrice)
          setErrors((prev) => ({ ...prev, secondName: `Заполните поле` }));
        if (!deliveryCity && deliveryPrice) setErrorDelivery(`Заполните поле`);
        if (!address && deliveryPrice)
          setErrors((prev) => ({ ...prev, address: `Заполните поле` }));
        if (!isValidPhone)
          setErrors((prev) => ({ ...prev, phone: "Введите валидный номер" }));

        let isDelivery =
          deliveryPrice ?
            firstName &&
            secondName &&
            deliveryCity &&
            isValidMail &&
            address &&
            isValidPhone
            :
            isValidMail &&
            isValidPhone;

        if (isDelivery) {
          dispatch(PaymentAC.isPayment(true));
          setTimeout(() => dispatch(PaymentAC.isPayment(false)), 3000);

          if (checkActualTicketsCount(ticketFormatsInBasket, goods)) {
            const sendGoods = getPaymentObject(goods, promocodes, email);

            sendGoods.delivery = {
              phone: phone.slice(1),
              location_id: deliveryCity?.id,
              // option: "courier",
              address,
              first_name: firstName,
              last_name: secondName,
            };
            dispatch(Operation.payment(sendGoods));
          } else {
            dispatch(
              ActionCreator.setRequiredTicketCountError(
                "Указанное количество билетов недоступно"
              )
            );
          }
        }
      } else {
        if (isValidMail) {
          dispatch(PaymentAC.isPayment(true));
          setTimeout(() => dispatch(PaymentAC.isPayment(false)), 3000);

          if (checkActualTicketsCount(ticketFormatsInBasket, goods)) {
            const sendGoods = getPaymentObject(goods, promocodes, email);
            dispatch(Operation.payment(sendGoods));
          } else {
            dispatch(
              ActionCreator.setRequiredTicketCountError(
                "Указанное количество билетов недоступно"
              )
            );
          }
        }
      }
    },
    [
      dispatch,
      isValidMail,
      goods,
      email,
      promocodes,
      firstName,
      secondName,
      deliveryCity,
      address,
      isValidPhone,
      phone,
      ticketFormatsInBasket,
    ]
  );

  const handleChange = () => {
    setIsUpdate(!isUpdate);
  };

  if (!goods) return null;
  return (
    <Wrapper>
      <Header />

      <Container>
        <Breadcrumbs />

        <div className={styles.basket}>
          <div className={styles.goodSection}>
            <div className={styles.titleWrap}>
              <div className={styles.titleText}>
                <h1 className={styles.title}>{t("basket.title")}</h1>
                <b className={styles.subtitle}>{t("basket.subtitle")}</b>
              </div>
              <Link className={styles.titleLink} to={routes.calendar.path}>
                {t("basket.btnLinkCalendar")}
              </Link>
            </div>
            {goods.length ? (
              <ul className={styles.mainList}>
                {goods.map((item, i) => (
                  <Goods handleChange={handleChange} key={`${item?._id}${i}`} item={item} />
                ))}
              </ul>
            ) : (
              <p className={styles.emptyBasketMessage}>
                {t("basket.emptyBasket")}
              </p>
            )}
          </div>

          {goods.length ? (
            <aside className={styles.asideSection}>
              <div className={styles.asideTitleWrap}>
                <b className={styles.asideTitle}>{t("basket.aside.title")}</b>
                <p className={styles.asideSubtitle}>
                  {t("basket.aside.subtitle")}
                </p>

                <div className={styles.warningMessage}>
                  <p className={styles.certificate}>
                    {t("basket.aside.ref")}
                    <span className={styles.certificateHelp}>
                      Описание для мед справки
                    </span>
                  </p>
                </div>
              </div>

              <ul className={styles.asideList}>
                {goods.filter((el) => el.selector === "ticket").length ? (
                  <li className={styles.asideItem}>
                    <div className={styles.asideItemTitleWrap}>
                      <h3>{t("basket.aside.events")}</h3>
                      <span>
                        <span>
                          {
                            goods.filter((el) => el.selector === "ticket")
                              .length
                          }
                        </span>{" "}
                        {t("basket.aside.count")}
                      </span>
                    </div>
                    <ul>
                      {goods.map((el, i) =>
                        el.selector === "ticket" ? (
                          <li
                            key={`${el._id}${i}`}
                            className={styles.activityItem}
                          >
                            <span className={styles.activityName}>
                              {el.size ? `${el.title} ${el.size}` : el.title}
                            </span>
                            <span>{el.totalCost} &#8381;</span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ) : null}

                {goods.filter((el) => el.selector === "certificate").length ? (
                  <li className={styles.asideItem}>
                    <div className={styles.asideItemTitleWrap}>
                      <h3>{t("basket.aside.certificates")}</h3>
                      <span>
                        <span>
                          {
                            goods.filter((el) => el.selector === "certificate")
                              .length
                          }
                        </span>{" "}
                        {t("basket.aside.count")}
                      </span>
                    </div>
                    <ul>
                      {goods.map((el, i) =>
                        el.selector === "certificate" ? (
                          <li
                            key={`${el._id}${i}`}
                            className={styles.activityItem}
                          >
                            <span className={styles.activityName}>
                              {el.size ? `${el.title} ${el.size}` : el.title}
                            </span>
                            <span>{el.totalCost} &#8381;</span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ) : null}

                {goods.filter((el) => el.selector === "shop").length ? (
                  <>
                    <li className={styles.asideItem}>
                      <div className={styles.asideItemTitleWrap}>
                        <h3>{t("basket.aside.shop")}</h3>
                        <span>
                          <span>
                            {
                              goods.filter((el) => el.selector === "shop")
                                .length
                            }
                          </span>{" "}
                          {t("basket.aside.count")}
                        </span>
                      </div>
                      <ul>
                        {goods.map((el, i) =>
                          el.selector === "shop" ? (
                            <li
                              key={`${el._id}${i}`}
                              className={styles.activityItem}
                            >
                              <span className={styles.activityName}>
                                {el.size ? `${el.title} ${el.size}` : el.title}
                              </span>
                              <span>{el.totalCost} &#8381;</span>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </li>
                    {isHiddenDeliveryAddres && isDelivery ? (
                      <>
                        <li className={styles.asideItem}>

                          <div className={styles.asideItemTitleWrap}>
                            <h3>Выберите город доставки</h3>
                            <span></span>
                          </div>

                          <div className={styles.deliverySelect}>
                            <CustomSelect
                              // error={errorDelivery}
                              handleSelectChange={setDeliveryReg}
                              handleFocus={handleDeliveryResetError}
                              value={deliveryReg}
                              prefix={`select-region`}
                              styles={styles}
                              title={``}
                              name={`delivery-region`}
                              options={setDeliveryOptions(deliveryRegions)}
                              placeholder={`Выберите регион доставки`}
                              closeMenuOnScroll={() => false}
                            />

                            <CustomSelect
                              error={errorDelivery}
                              handleSelectChange={setDeliveryCity}
                              handleFocus={handleDeliveryResetError}
                              value={deliveryCity}
                              prefix={`select-delivery-city`}
                              styles={styles}
                              title={``}
                              name={`delivery-city`}
                              options={setDeliveryOptions(deliveryCities)}
                              placeholder={`Выберите город доставки`}
                              closeMenuOnScroll={() => false}
                            />
                          </div>

                        </li>

                        {deliveryPrice && <li className={styles.asideItem}>
                          <div className={styles.asideItemTitleWrap}>
                            <h3>Заполните данные</h3>
                            <span>{isValidMail ? " " : null}</span>
                          </div>

                          <label
                            className={styles.label}
                            htmlFor="send-basket-name"
                          >
                            Имя
                          </label>
                          <input
                            value={firstName}
                            error={isErrors.firstName}
                            onChange={handleInputs}
                            className={styles.sendBlockInputs}
                            type="text"
                            name="name"
                            id="send-basket-name"
                          />

                          <label
                            className={styles.label}
                            htmlFor="send-basket-surname"
                          >
                            Фамилия
                          </label>
                          <input
                            value={secondName}
                            error={isErrors.secondName}
                            onChange={handleInputs}
                            className={styles.sendBlockInputs}
                            type="text"
                            name="surname"
                            id="send-basket-surname"
                          />

                          <div className={styles.inputGroup}>
                            <label
                              className={styles.label}
                              htmlFor="address-text"
                            >
                              Ваш адрес
                            </label>
                            <textarea
                              error={isErrors.address}
                              className={`${styles.textarea}`}
                              autoComplete="off"
                              name="address"
                              id="address-text"
                              value={address}
                              onChange={handleInputs}
                            // ref={}
                            // placeholder={t('feedback.form.fieldLabel7')}
                            />
                          </div>
                        </li>}

                        <li className={styles.asideItem}>
                          <div className={styles.asideItemTitleWrap}>
                            <h3>Оплата заказа</h3>
                            <span>
                              <span>{goods.length}</span>{" "}
                              {t("basket.aside.count")}
                            </span>
                          </div>
                          <ul>
                            <li className={styles.activityItem}>
                              <span className={styles.activityName}>
                                {t("basket.aside.events")}:
                              </span>
                              <span>
                                {goods
                                  .filter((el) => el.selector === "ticket")
                                  .reduce((a, b) => a + b.totalCost, 0)
                                  .toFixed(0)}{" "}
                                &#8381;
                              </span>
                            </li>

                            <li className={styles.activityItem}>
                              <span className={styles.activityName}>
                                {t("basket.aside.certificates")}:
                              </span>
                              <span>
                                {goods
                                  .filter((el) => el.selector === "certificate")
                                  .reduce((a, b) => a + b.totalCost, 0)
                                  .toFixed(0)}{" "}
                                &#8381;
                              </span>
                            </li>

                            <li className={styles.activityItem}>
                              <span className={styles.activityName}>
                                {t("basket.aside.shop")}:
                              </span>
                              <span>
                                {goods
                                  .filter((el) => el.selector === "shop")
                                  .reduce((a, b) => a + b.totalCost, 0)
                                  .toFixed(0)}{" "}
                                &#8381;
                              </span>
                            </li>

                            <li className={styles.activityItem}>
                              {deliveryPrice && (<>  <span className={styles.activityName}>
                                Доставка:
                              </span>
                                <span>{deliveryPrice?.cost} &#8381;</span> </>)}
                            </li>
                          </ul>
                        </li>
                      </>
                    ) : null}
                  </>
                ) : null}
              </ul>

              <div className={styles.totalCostWrap}>
                {totalDiscount !== 0 && (
                  <div className={styles.saleWrap}>
                    <span>{t("basket.aside.discount")}:</span>
                    <span>{totalDiscount} &#8381;</span>
                  </div>
                )}
                {giftDiscount.value !== 0 && (
                  <div className={styles.saleWrap}>
                    <span>
                      {t("basket.aside.giftDiscount")} ({giftDiscount.volume}):
                    </span>
                    <span>{giftDiscount.value.toLocaleString()} &#8381;</span>
                  </div>
                )}
                <div className={styles.totalCost}>
                  <span>{t("basket.aside.totalCost")}:</span>
                  {isDelivery && deliveryPrice?.cost ? (
                    <span className={styles.cost}>
                      {(
                        goods.reduce((a, b) => a + b.totalCost, 0) +
                        Number(deliveryPrice?.cost) -
                        Number(totalDiscount) -
                        Number(giftDiscount.value)
                      ).toLocaleString()}{" "}
                      &#8381;
                    </span>
                  ) : (
                    <span className={styles.cost}>
                      {(
                        goods.reduce((a, b) => a + b.totalCost, 0) -
                        totalDiscount -
                        giftDiscount.value
                      ).toLocaleString()}{" "}
                      &#8381;
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.sendBlock}>
                {isHiddenDeliveryAddres && isDelivery ? (
                  <InputPhone
                    error={isErrors.phone}
                    value={phone}
                    country="RU"
                    international={true}
                    withCountryCallingCode={true}
                    id={`phone`}
                    label={``}
                    onChange={handlePhoneChange}
                    onFocus={handlePhoneFocus}
                    outsideStyle={styles}
                    name={`phone`}
                  />
                ) : null}

                <label className="visually-hidden" htmlFor="send-basket-email">
                  {t("basket.aside.emailInput")}
                </label>
                <input
                  value={email}
                  onChange={handleInputChange}
                  className={styles.sendBlockInput}
                  type="text"
                  name="email"
                  id="send-basket-email"
                  placeholder={t("basket.placeholder.email")}
                />
                <button
                  className={styles.btn}
                  type="button"
                  onClick={handlePayment}
                  disabled={!isValidMail || !goods.length || isPayment || isDisabled || isDeliveryDisabled/* || requestPayment */}
                >
                  {t("basket.aside.paymentBtn")}
                </button>
                <p className={styles.sendCondition}>
                  {t("basket.aside.conditions.part1")}{" "}
                  <a href="/api/files/document/pers" target="_blank" rel="noopener noreferrer">
                    {t("basket.aside.conditions.link1")}
                  </a>
                  {t("basket.aside.conditions.part2")}{" "}
                  <a href="/api/files/document/offers" target="_blank" rel="noopener noreferrer">
                    {t("basket.aside.conditions.link2")}
                  </a>
                  {t("basket.aside.conditions.part3")}{" "}
                  <a href="/api/files/document/insurance_rules" target="_blank" rel="noopener noreferrer">
                    {t("basket.aside.conditions.link3")}
                  </a>
                  {t("basket.aside.conditions.part4")}{" "}
                  <a href="/api/files/document/insurance_cond" target="_blank" rel="noopener noreferrer">
                    {t("basket.aside.conditions.link4")}
                  </a>
                  {t("basket.aside.conditions.part5")}
                </p>

                <div className={styles.paymentLogotypes}>
                  <img alt="visa" src={visa} className={styles.paymentImg} />
                  <img alt="mc" src={mc} className={styles.paymentImg} />
                  <img alt="mir" src={mir} className={styles.paymentImg} />
                  <img alt="uniteller" src={uniteller} className={styles.paymentImg} />
                </div>

                <div className={styles.paymentLogotypes}><span onClick={() => setSecurityPaymentPopup(!securityPaymentPopup)} className={styles.securityPayment}>Безопасность платежей</span></div>

              </div>
            </aside>
          ) : null}
        </div>

        {requestPayment && <form id="form-payment" action={requestPayment.redirect_url} method={requestPayment.method || "GET"} hidden={true}>

          {paymentFormKeys && paymentFormKeys.length > 0 ? paymentFormKeys.map((el) => (
            <input onChange={() => { }} key={el} name={el} value={requestPayment.form[el]} />

          )) : <input onChange={() => { }} name="default" value="" />}

          <button ref={formButton} type="submit"></button>
        </form>}
        {securityPaymentPopup && <UniversalPopup><div>
          <div style={{ padding: "40px", backgroundColor: "black", maxWidth: "1000px" }}>
            <div>
              <h4>Уважаемый клиент!</h4>
              <p className={styles.securityP}>Вы можете оплатить свой заказ онлайн с помощью банковской карты через платежный сервис компании Uniteller. После подтверждения заказа Вы будете перенаправлены на защищенную платежную страницу Uniteller, где необходимо будет ввести данные для оплаты заказа. После успешной оплаты на указанную в форме оплаты электронную почту будет направлен электронный чек с информацией о заказе и данными по произведенной оплате.</p>
              <h4>Гарантии безопасности</h4>
              <p className={styles.securityP}>Безопасность процессинга Uniteller подтверждена сертификатом стандарта безопасности данных индустрии платежных карт PCI DSS. Надежность сервиса обеспечивается интеллектуальной системой мониторинга мошеннических операций, а также применением 3D Secure - современной технологией безопасности интернет-платежей.
                Данные Вашей карты вводятся на специальной защищенной платежной странице. Передача информации в процессинговую компанию Uniteller происходит с применением технологии шифрования TLS. Дальнейшая передача информации осуществляется по закрытым банковским каналам, имеющим наивысший уровень надежности.</p>
              <h4>Uniteller не передает данные Вашей карты магазину и иным третьим лицам!</h4>
              <p className={styles.securityP}>Если Ваша карта поддерживает технологию 3D Secure, для осуществления платежа, Вам необходимо будет пройти дополнительную проверку пользователя в банке-эмитенте (банк, который выпустил Вашу карту). Для этого Вы будете направлены на страницу банка, выдавшего карту. Вид проверки зависит от банка. Как правило, это дополнительный пароль, который отправляется в SMS, карта переменных кодов, либо другие способы.</p>
            </div>
            <button className={styles.popupBtn} onClick={() => setSecurityPaymentPopup(false)}>
              Закрыть
            </button>
          </div>
        </div></UniversalPopup>}
        {/* <PreviewCollection collectionName="shop" /> */}
        <ExternalCollection slides={slides} />

        <Similar style={styles.similarWrap} />

        {/* <Offers style={styles.offersWrap} /> */}
      </Container>

      <Footer />

      {isErrorPayment && (
        <UniversalPopup>
          <div className={styles.popupWrapper}>
            <h2 className={styles.popupTitle}>{errorPayment.message}</h2>
            <div className={styles.popupBtnWrap}>
              <button className={styles.popupBtn} onClick={handlePaymentError}>
                Вернуться в корзину
              </button>
            </div>
          </div>
        </UniversalPopup>
      )}

      {requiredTicketCountError.length ? (
        <UniversalPopup>
          <div className={styles.popupWrapper}>
            <h2 className={styles.popupTitle}>{requiredTicketCountError}</h2>
            <div className={styles.popupBtnWrap}>
              <button
                className={styles.popupBtn}
                onClick={() =>
                  dispatch(ActionCreator.setRequiredTicketCountError(""))
                }
              >
                Понятно
              </button>
            </div>
          </div>
        </UniversalPopup>
      ) : null}
      {timePaymentPopupError && (
        <UniversalPopup>
          <div className={styles.popupWrapper}>
            <h2 className={styles.popupTitle}>{`Данные для покупки ${timePaymentPopupError?.event?.title} устарели, пожалуйста добавьте в корзину снова`}</h2>
            <div className={styles.popupBtnWrap}>
              <button
                className={styles.popupBtn}
                onClick={() => setTimePaymentPopupError(null)}
              >
                Понятно
              </button>
            </div>
          </div>
        </UniversalPopup>
      )}
    </Wrapper>
  );
};

export default Basket;
