import { useEffect, useRef, useState } from 'react';
import Input from 'components/Forms/Input/Input';
import { ReactComponent as ReactProfileIcon } from 'images/profile/profile-name.svg';
import Datepicker from 'components/Forms/Datepicker/Datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreator, Operation } from 'reducer/profile/profile';
import { getDataMessage } from 'reducer/profile/selectors';
import { getDate, useValidationDate } from 'customHooks/useValidationDate';
import { ReactComponent as DateLogo } from 'images/profile/profile-calendar.svg';
import { objectEqual } from 'utils/utils';
import { useTranslation } from 'react-i18next';


const Data = ({ styles, isLoading, setLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nameRef = useRef();
  const messageRef = useRef();

  const [userObj, setUserObj] = useState(JSON.parse(localStorage.userObj));
  const [userName, setUserName] = useState(userObj.name);
  const [lastName, setLastName] = useState(userObj.last_name);
  const [valueDate, handleDateChange, date, isValidDate, setValueDate] = useValidationDate("");

  useEffect(() => {
    setValueDate(getDate(userObj.birth_date * 1000));
  }, []);

  const dataMessage = useSelector(state => getDataMessage(state));

  // пользовательская информация хранится в LocalStorage
  // - можно попробовать хранить с помощью редакса
  useEffect(() => {
    setUserObj(JSON.parse(localStorage.userObj));

    // при рендере компонента объявляется таймер,
    // для удаления сообщений об успешном обновлении данных
    let timer = setTimeout(() => {
      dispatch(ActionCreator.setStatus(false));
      dispatch(ActionCreator.setMessage(``));
    }, 5000);

    return () => clearTimeout(timer);
  }, [dispatch]);


  // основная функция отправки измененных данных / для обновления информации
  const changeDataHandler = (evt) => {
    setLoading(true);
    setTimeout(() => setLoading(false), 3000);

    evt.preventDefault();
    // проверка новых/измененных данных
    const userNameChanges = userName && userName !== userObj.name;
    const lastNameChanges = lastName && lastName !== userObj.last_name;
    const dateChanges = date && objectEqual(date, new Date(userObj.birth_date));

    // если обновились все - данные - обновляем все или по отдельности
    if (userNameChanges && dateChanges && lastNameChanges) {
      dispatch(Operation.setNewData({ name: userName, last_name: lastName, birth_date: date }));
    } else {
      if (userNameChanges) {
        dispatch(Operation.setNewData({ name: userName }));
      }
      if (lastNameChanges) {
        dispatch(Operation.setNewData({ last_name: lastName }));
      }
      if (dateChanges) {
        dispatch(Operation.setNewData({ birth_date: date }));
      } else if (!isValidDate) {
        dispatch(ActionCreator.setMessage(`Введите дату в правильном формате`));
        setLoading(false);
      }
      if (!userNameChanges && !dateChanges && !lastNameChanges) {
        dispatch(ActionCreator.setMessage(`Данные не изменились`));
        setLoading(false);
      }
    }
  };

  const handleFocus = () => dispatch(ActionCreator.setMessage(``));

  return (
    <div className={styles.formsWrap}>
      <div className={styles.formsWrapGrid}>
        <Input
          error={``}
          handleFieldChange={(evt) => setUserName(evt.target.value)}
          handleFocus={handleFocus}
          Logo={ReactProfileIcon}
          label={t('lk.data.name')} name={`name`} id={`profile-name`}
          ref={nameRef}
          defaultValue={userName}
        />

        <Input
          error={``}
          handleFieldChange={(evt) => setLastName(evt.target.value)}
          handleFocus={handleFocus}
          Logo={ReactProfileIcon}
          label={t('lk.data.lname')} name={`lastname`} id={`profile-lastname`}
          ref={nameRef}
          defaultValue={lastName}
        />

        <Datepicker
          error={``}
          value={valueDate}
          handleDateChange={handleDateChange}
          handleDateFocus={handleFocus}
          Logo={DateLogo}
          label={t('lk.data.bd')}
          id={`birth_date`}
        />
        {dataMessage.length ? <p className={styles.message} ref={messageRef}>{dataMessage}</p> : null}
      </div>
      <button
        disabled={isLoading}
        type="button"
        className={`${styles.settingBtn} ${styles.settingBtnFirst}`}
        onClick={changeDataHandler}
      >{t('lk.data.btn')}</button>
    </div>
  );
};

export default Data;
