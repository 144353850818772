export const updateObjectInArray = (array, action) => {
  return array.map((item) => {
    if (action.selector === `shop`) {
      if (item.public_id === action.public_id && item.size === action.size) {
        return { ...item, ...action };
      }
    } else {
      // return an updated value
      if (item.public_id === action.public_id) return { ...item, ...action };
    }
    return item;
  });
};

export const updateObject = (obj, payload) => {
  return { ...obj, ...payload };
};

export const returnNewArrayById = (
  arr,
  payload,
  selector = `format_public_id`
) => {
  const newValue = arr.find((el) => el[selector] === payload[selector]);

  if (newValue === undefined) {
    // если элемента нет - добавляет
    return [...arr, payload];
  } else {
    // если элемент есть заменяет его с обновленными значениями
    const filterArr = arr.filter((el) => el[selector] !== payload[selector]);
    return [...filterArr, { ...newValue, ...payload }];
  }
};

export const returnNewShopItem = (arr, payload) => {
  const oldValue = arr.find(
    (el) => el.public_id === payload.public_id && el.size === payload.size
  );

  if (oldValue) {
    const filterArr = arr.filter((el) => {
      return !(el.public_id === payload.public_id && el.size === payload.size);
    });

    return [...filterArr, { ...oldValue, ...payload }];
  } else return [...arr, payload];
};

export const returnNewArrayByType = (arr, payload) => {
  let cb = () => { },
    filterCb = () => { };
  if (payload.selector === `shop`) {
    cb = (el, payload) =>
      el.public_id === payload.public_id && el.size === payload.size;
    filterCb = (el, payload) =>
      !(el.public_id === payload.public_id && el.size === payload.size);
  } else {
    cb = (el, payload) => el.public_id === payload.public_id;
    filterCb = (el) => el.public_id !== payload.public_id;
  }

  const oldValue = arr.find(cb);

  if (oldValue) {
    const filterArr = arr.filter(filterCb);
    return [...filterArr, { ...oldValue, ...payload }];
  } else return [...arr, payload];
};

// #TODO - СДЕЛАТЬ ОБЪЕДИНЕНИЕ С УДАЛЕНИЕМ ТОВАРА ИЗ МАГАЗИНА!
export const removeItem = (array, id) => {
  return array.filter((item) => item.public_id !== id);
};

export const removeItemBasket = (arr, item) => {
  if (item.selector === `shop`) {
    return arr.filter(
      (el) => !(el.public_id === item.public_id && el.size === item.size)
    );
  } else {
    return arr.filter((el) => el.public_id !== item.public_id);
  }
};

export const updateLocalStorage = (store, action, payload) => {
  let oldData = [];
  let newData = [];

  if (localStorage.getItem(store)) {
    oldData = JSON.parse(localStorage.getItem(store));
  }

  if (action === `add`) {
    if (payload.selector === `shop`) {
      newData = returnNewShopItem(oldData, payload);
    } else newData = returnNewArrayById(oldData, payload); // add + update array
  } else if (action === `delete`) {
    newData = removeItemBasket(oldData, payload); // delete from array
  } else if (action === `update`) {
    newData = updateObject(oldData, payload); // update one object
  } else if (action === `deleteAll`) {
    newData = [];
  }

  localStorage.setItem(store, JSON.stringify(newData));
};

export const returnElementById = (arr, item, selector = `public_id`) => {
  if (arr.length) {
    if (item.selector === "shop") {
      return arr.find(
        (elem) => elem[selector] === item.public_id && elem.size === item.size
      );
    } else {
      return arr.find((elem) => elem[selector] === item.public_id);
    }
  } else {
    return null;
  }
};

// export const renderCaseName = (count, name) => {
//   if (count === 1) {
//     return `${name}`;
//   } else if (count > 1 && count < 5) {
//     return `${name}a`;
//   } else if (count >= 5) {
//     return `${name}ов`;
//   }
// };

export const returnNewArrByElementKey = (array, newElement, key) => {
  if (array.length) {
    return array.map((el) => {
      if (el[key] === newElement[key]) return newElement;
      else return el;
    });
  } else {
    return [newElement];
  }
};

export const objectEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) !== JSON.stringify(obj2);
};

// Function for capitalize first letter in word
export const capitalizeFirstLetter = (word) => {
  if (word && word !== undefined && word.length) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  } else return undefined;
};

export const searchTeam = (teams, team_public_id, max_count) => {
  const currentTeam = teams.find((t) => t.public_id === team_public_id);
  if (currentTeam) {
    const { number } = currentTeam;
    const { public_id } = currentTeam;
    return {
      value: public_id,
      label: `${number} (свободно ${currentTeam.tickets_left} мест из ${(max_count && max_count) || "10"
        })`,
    };
  } else return { value: null, label: "Команда не выбрана" };
};

export const searchCity = (cities, cityEventId) => {
  if (Array.isArray(cities)) {
    let currentCity = cities.find((city) => city.public_id === cityEventId);

    return currentCity ? currentCity.formats : [];
  } else return [];
};

export function convertQueryParamsToObject(queryString) {
  const result = {};
  const params = queryString.substr(1).split("&");

  if (params !== "") {
    for (let i = 0; i < params.length; i += 1) {
      const param = params[i].split("=");
      if (param.length === 2) {
        result[param[0]] = decodeURIComponent(param[1]);
      }
    }
  }
  return result;
}

export const getRightNames = (num, one, two, five) => {
  let n = Math.abs(num);

  n %= 100;

  if (n >= 5 && n <= 20) {
    return five;
  }

  n %= 10;

  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }

  return five;
};
